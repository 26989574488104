// change the content of coder.py to this file using the javascript format
var sum = function(x,y){ return x+y;};　　//求和函数
var square = function(x){ return x*x;};
var getverficationcode=(data)=>{
    var sum = function(x,y){ return x+y;};　　//求和函数
    var square = function(x){ return x*x;};　　//数组中每个元素求它的平方
    var mean = data.reduce(sum)/data.length;
    var deviations = data.map(function(x){return x-mean;});
    var stddev = Math.sqrt(deviations.map(square).reduce(sum)/(data.length-1));
    return stddev
}
var range=(start=0,end,step=1,callback)=>{
    for(var i=start;i<end;i+=step){
        callback(i);
    }
}
var chr=(string)=>{
    return String.fromCharCode(string);
}
var istoken=(string)=>{
    return string == '!' || string == '-' || string == '+'||string=="|"||string=="."||string==`"`
} 
var encode=(code,accept_timeout=1000)=>{
    //the result of function will be look like this
    //{body}-{send_timestamp}!{timeout}+{add_each_value}|{verfication_code}
    var tmp_container={};
    var dic= '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~ '
    var salt_list = []
    //add the key of the tmp_container
    tmp_container['body']=""
    tmp_container['send_timestamp']=""
    tmp_container['timeout']=""
    tmp_container['add_each_value']=""
    tmp_container['verfication_code']=""
    tmp_container["origindata"]=[]
    tmp_container["tmp"]=""
    range(59,127,1,(i)=>{
        if(istoken(chr(i))!=true){
            salt_list.push(chr(i))
        }
    })
    // copy it range(32,46)
    range(32,46,1,(i)=>{
        if(istoken(chr(i))!=true){
            salt_list.push(chr(i))
        }
    })
    //console.log(salt_list.length)
    //get the million seconds timestam from now called ts
    var ts = new Date().getTime()
    
    //generate the random changebetween 1 and 920
    var change = Math.floor(Math.random()*920)+1
    for( var i in code){
        
        var index=dic.indexOf(code[i])
        if(index!=-1){
            //transfrom the index like the 001-999
            tmp_container['origindata'].push(index)
            var tmp_index=(index+change).toString()
            switch(tmp_index.length){
                case 1:
                    tmp_index="00"+tmp_index
                    break;
                case 2:
                    tmp_index="0"+tmp_index
                    break;
                case 3:
                    tmp_index=tmp_index
                    break;
                default:
                    break;
            }
            tmp_container["tmp"]+=tmp_index

        }
    }
    for(var i in tmp_container["tmp"]){
        var salt = salt_list[Math.floor(Math.random()*salt_list.length)]
        tmp_container['body']+=tmp_container["tmp"][i]+salt
    }
    //add the send_timestamp with add some salt before the each of the string of ts
    range(0,ts.toString().length,1,(i)=>{
        var salt = salt_list[Math.floor(Math.random()*salt_list.length)]
        tmp_container["send_timestamp"]+=salt+ts.toString()[i]
    })
    //add the timeout with add some salt before the each of the string of accept_timeout
    range(0,accept_timeout.toString().length,1,(i)=>{
        var salt = salt_list[Math.floor(Math.random()*salt_list.length)]
        tmp_container["timeout"]+=salt+accept_timeout.toString()[i]
    })
    //add the add_each_value with add some salt before the each of the string of change
    range(0,change.toString().length,1,(i)=>{
        var salt = salt_list[Math.floor(Math.random()*salt_list.length)]
        tmp_container["add_each_value"]+=salt+change.toString()[i]
    })
    //add the verfication_code with add some salt before the each of the string of change
    var verfication_code=getverficationcode(tmp_container["origindata"])
    range(0,verfication_code.toString().length,1,(i)=>{
        var salt = salt_list[Math.floor(Math.random()*salt_list.length)]
        tmp_container["verfication_code"]+=salt+verfication_code.toString()[i]
    })
    //return the result with the format of key
    var result=tmp_container["body"]+"-"+tmp_container["send_timestamp"]+"!"+tmp_container["timeout"]+"+"+tmp_container["add_each_value"]+"|"+tmp_container["verfication_code"]
    return result

}
var decode=(key)=>{
    var dic='0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~ '
    var salt_list = []
    range(59,127,1,(i)=>{
        if(istoken(chr(i))!=true){
            salt_list.push(chr(i))
        }
    })
    // copy it range(32,46)
    range(32,46,1,(i)=>{
        if(istoken(chr(i))!=true){
            salt_list.push(chr(i))
        }
    })
    var tmp=[]
    for (var i in key) {
      //console.log(data[i])
      //console.log(salt_list.indexOf(data[i])==-1)
      if(salt_list.indexOf(key[i])==-1){
        tmp.push(key[i])
      }
      
    }
    //console.log(tmp)
    tmp=tmp.join('')
    //the key look like this
    //{body}-{send_timestamp}!{timeout}+{add_each_value}|{verfication_code}
    //get the params from the key
    //the body param is before the token "-"
    var origindata = tmp.split('-')[0]
    //the send_timestamp param is between the token "-" and the token "!"
    var send_timestamp = tmp.split('-')[1].split('!')[0]
    //the timeout param is between the token "!" and the token "+"
    var timeout = tmp.split('!')[1].split('+')[0]
    //the add_each_value param is between the token "+" and the token "|"
    var add_each_value = tmp.split('+')[1].split('|')[0]
   
    //the verfication_code param is after the token "|"
    var verfication_code = tmp.split('|')[1]
    //print the params {paramname}: {paramvalue}
    //console.log("origindata: "+origindata)
    //console.log("send_timestamp: "+send_timestamp)
    //console.log("timeout: "+timeout)
   // console.log("add_each_value: "+add_each_value)
    //console.log("verfication_code: "+verfication_code)


    var data=""
    for(var i=0,len=origindata.length;i<len;i++){
      data += origindata[i];
      //if the add character num is the times of three insert "," to data lasr
        if(i%3==2 && i!=len-1){
            data+=","
        }
    }
   // console.log(data)
    data=data.split(',')
    //console.log(data)
    var temp=[]
    for(i=0,len=data.length;i<len;i++){
      //deposite the data like "00x" "0x" "xxx" transfer to number type and put it in the temp 
        temp.push(parseInt(data[i]))
        
    }
    //console.log(temp)
    data=temp
    temp=[]
    for(i=0,len=data.length;i<len;i++){
      temp.push(Math.abs(data[i]-add_each_value))
    }
    //console.log(temp)
   //console.log(getverficationcode(temp))
    //console.log(typeof(Number(verfication_code)))
    if(getverficationcode(temp)!=Number(verfication_code)){
        return [403,"the data was hacked by the hacker"]
    }
    data=temp
    var result=[];
    for(i=0;i<data.length;i++){
      result.push(dic[data[i]])
    }
    result=result.join('')
    //console.log(result)
    var now=Date.now()
    if(now<(eval(timeout)+eval(send_timestamp))){
  
      //console.log('success')
      //console.log(result)
      return [200,result];
    }else{
        //console.log('fail')
        return [500,"timeout"]
    }


  }
//export the function
class Adapter{
    constructor(){
        this.encode=encode
        this.decode=decode
    }
    Encode(data,timeOut=1000){
        return encode(data,timeOut)
    }
    Decode(data){
        return decode(data)
    }
    Decrypt(data){
        return decode(data)
    }
    Encrypt(data,timeOut=1000){
        return encode(data,timeOut)
    }
}
export default Adapter